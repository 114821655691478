// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/icomoon.eot?mn6gsy", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/icomoon.ttf?mn6gsy", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/icomoon.woff?mn6gsy", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/icomoon.svg?mn6gsy", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'icomoon';
  src:  url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src:  url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-aptitude:before {
  content: "\\e900";
}
.icon-coding:before {
  content: "\\e902";
}
.icon-communication:before {
  content: "\\e903";
}
.icon-dashboard:before {
  content: "\\e904";
}
.icon-mentoring:before {
  content: "\\e905";
}
.icon-mock_interviews:before {
  content: "\\e906";
}
.icon-practice_test:before {
  content: "\\e907";
}
.icon-premium:before {
  content: "\\e908";
}
.icon-reports:before {
  content: "\\e909";
}
`, "",{"version":3,"sources":["webpack://./src/styles/icon-fonts/icons.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,6CAAqC;EACrC;;;yDAGuD;EACvD,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,+EAA+E;EAC/E,iCAAiC;EACjC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,oBAAoB;EACpB,cAAc;;EAEd,sCAAsC;EACtC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: 'icomoon';\n  src:  url('fonts/icomoon.eot?mn6gsy');\n  src:  url('fonts/icomoon.eot?mn6gsy#iefix') format('embedded-opentype'),\n    url('fonts/icomoon.ttf?mn6gsy') format('truetype'),\n    url('fonts/icomoon.woff?mn6gsy') format('woff'),\n    url('fonts/icomoon.svg?mn6gsy#icomoon') format('svg');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n[class^=\"icon-\"], [class*=\" icon-\"] {\n  /* use !important to prevent issues with browser extensions that change fonts */\n  font-family: 'icomoon' !important;\n  speak: never;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n\n  /* Better Font Rendering =========== */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-aptitude:before {\n  content: \"\\e900\";\n}\n.icon-coding:before {\n  content: \"\\e902\";\n}\n.icon-communication:before {\n  content: \"\\e903\";\n}\n.icon-dashboard:before {\n  content: \"\\e904\";\n}\n.icon-mentoring:before {\n  content: \"\\e905\";\n}\n.icon-mock_interviews:before {\n  content: \"\\e906\";\n}\n.icon-practice_test:before {\n  content: \"\\e907\";\n}\n.icon-premium:before {\n  content: \"\\e908\";\n}\n.icon-reports:before {\n  content: \"\\e909\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
